import {
  GetAllCodelistenResolver,
  GetAllResolver,
  GetAllVerfahrenResolver,
  GetBlacklistResolver,
  GetNutzerVerfahrenResolver,
} from "@/router/services.js";

export const detailRoutes = {
  path: "/details/:planID/",
  component: () => import("@/views/VDetail.vue"),
  name: "DetailPage",
  props: true,
  meta: {
    resolve: {
      "/verfahren": GetAllVerfahrenResolver,
      "/nutzer/verfahren": GetNutzerVerfahrenResolver,
      "/konfigurationen/verfahren": GetAllResolver,
      "/konfigurationen/codelisten": GetAllCodelistenResolver,
      "/konfigurationen/blacklist": GetBlacklistResolver,
    },
  },
  children: [
    {
      path: "stammdaten",
      component: () => import("@/views/VDetailData.vue"),
      meta: {
        title: ["{proceedingName}", "Stammdaten"],
        label: "Stammdaten",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },
    {
      path: "zeitplanung",
      component: () => import("@/views/VDetailSchedule.vue"),
      name: "zeitplanung",
      meta: {
        title: ["{proceedingName}", "Zeitplanung"],
        label: "Zeitplanung",
        requiredPermissions: {
          0: ["VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
          1: ["EIGENE_VERFAHREN_READ", "EIGENE_VERFAHREN_WRITE"],
        },
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },
    {
      path: ":vtsID/:roundID/:uvtsID?/:taskID?",
      component: () => import("@/views/VDetailTasks.vue"),
      meta: {
        title: ["{proceedingName}", "Aufgaben"],
        label: "Aufgaben",
        isTasksView: true,
        requiredPermissions: ["VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },
    {
      path: "planwerke",
      component: () => import("@/views/VDetailPlanwerke.vue"),
      meta: {
        title: ["{proceedingName}", "Planwerke"],
        label: "Planwerke",
        requiredPermissions: ["ADMIN", "PLANWERKE_READ", "EIGENE_PLANWERKE_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
        },
      },
    },
    {
      path: "dokumente",
      component: () => import("@/views/VDetailDocuments.vue"),
      meta: {
        title: ["{proceedingName}", "Dokumente"],
        label: "Dokumente",
        requiredPermissions: ["ADMIN", "DOKUMENT_READ", "EIGENE_DOKUMENT_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
        },
      },
    },

    {
      path: "stellungnahmen/:vsID?/:vtsID?/:roundID?/",
      component: () => import("@/views/VDetailStatements.vue"),
      meta: {
        title: ["{proceedingName}", "Stellungnahmen"],
        label: "Stellungnahmen",
        isStatementsView: true,
        requiredPermissions: {
          0: ["VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
          1: ["EIGENE_STELLUNGNAHME_READ", "EIGENE_STELLUNGNAHME_WRITE"],
        },
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },

    {
      path: "protokolle",
      component: () => import("@/views/VDetailProtocols.vue"),
      meta: {
        title: ["{proceedingName}", "Protokolle"],
        label: "Protokolle",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },
    {
      path: "einstellungen",
      component: () => import("@/views/VDetailSettings.vue"),
      meta: {
        title: ["{proceedingName}", "Einstellungen"],
        label: "Einstellungen",
        requiredPermissions: ["VERFAHREN_EINSTELLUNGEN"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
        },
      },
    },
  ],
};
