/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.136.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { RechtszitatDetailRest } from '../models';
// @ts-ignore
import { RechtszitatEditRest } from '../models';
// @ts-ignore
import { RechtszitatNeuRest } from '../models';
/**
 * RechtszitatResourceApi - axios parameter creator
 * @export
 */
export const RechtszitatResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {RechtszitatNeuRest} rechtszitatNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRechtszitat: async (rechtszitatNeuRest: RechtszitatNeuRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rechtszitatNeuRest' is not null or undefined
            assertParamExists('createRechtszitat', 'rechtszitatNeuRest', rechtszitatNeuRest)
            const localVarPath = `/konfigurationen/rechtszitate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rechtszitatNeuRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRechtszitat: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteRechtszitat', 'name', name)
            const localVarPath = `/konfigurationen/rechtszitate/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRechtszitat: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('findRechtszitat', 'name', name)
            const localVarPath = `/konfigurationen/rechtszitate/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRechtszitat: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/rechtszitate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {RechtszitatEditRest} rechtszitatEditRest 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRechtszitat: async (rechtszitatEditRest: RechtszitatEditRest, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rechtszitatEditRest' is not null or undefined
            assertParamExists('updateRechtszitat', 'rechtszitatEditRest', rechtszitatEditRest)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateRechtszitat', 'name', name)
            const localVarPath = `/konfigurationen/rechtszitate/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rechtszitatEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRechtszitat: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadRechtszitat', 'file', file)
            const localVarPath = `/konfigurationen/rechtszitate/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RechtszitatResourceApi - functional programming interface
 * @export
 */
export const RechtszitatResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RechtszitatResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {RechtszitatNeuRest} rechtszitatNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRechtszitat(rechtszitatNeuRest: RechtszitatNeuRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RechtszitatDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRechtszitat(rechtszitatNeuRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RechtszitatResourceApi.createRechtszitat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRechtszitat(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRechtszitat(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RechtszitatResourceApi.deleteRechtszitat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRechtszitat(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RechtszitatDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRechtszitat(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RechtszitatResourceApi.findRechtszitat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRechtszitat(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RechtszitatDetailRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRechtszitat(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RechtszitatResourceApi.getAllRechtszitat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {RechtszitatEditRest} rechtszitatEditRest 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRechtszitat(rechtszitatEditRest: RechtszitatEditRest, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RechtszitatDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRechtszitat(rechtszitatEditRest, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RechtszitatResourceApi.updateRechtszitat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadRechtszitat(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RechtszitatDetailRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadRechtszitat(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RechtszitatResourceApi.uploadRechtszitat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RechtszitatResourceApi - factory interface
 * @export
 */
export const RechtszitatResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RechtszitatResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {RechtszitatNeuRest} rechtszitatNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRechtszitat(rechtszitatNeuRest: RechtszitatNeuRest, options?: any): AxiosPromise<RechtszitatDetailRest> {
            return localVarFp.createRechtszitat(rechtszitatNeuRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRechtszitat(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRechtszitat(name, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRechtszitat(name: string, options?: any): AxiosPromise<RechtszitatDetailRest> {
            return localVarFp.findRechtszitat(name, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRechtszitat(options?: any): AxiosPromise<Array<RechtszitatDetailRest>> {
            return localVarFp.getAllRechtszitat(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {RechtszitatEditRest} rechtszitatEditRest 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRechtszitat(rechtszitatEditRest: RechtszitatEditRest, name: string, options?: any): AxiosPromise<RechtszitatDetailRest> {
            return localVarFp.updateRechtszitat(rechtszitatEditRest, name, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRechtszitat(file: File, options?: any): AxiosPromise<Array<RechtszitatDetailRest>> {
            return localVarFp.uploadRechtszitat(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RechtszitatResourceApi - object-oriented interface
 * @export
 * @class RechtszitatResourceApi
 * @extends {BaseAPI}
 */
export class RechtszitatResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
     * @param {RechtszitatNeuRest} rechtszitatNeuRest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechtszitatResourceApi
     */
    public createRechtszitat(rechtszitatNeuRest: RechtszitatNeuRest, options?: RawAxiosRequestConfig) {
        return RechtszitatResourceApiFp(this.configuration).createRechtszitat(rechtszitatNeuRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechtszitatResourceApi
     */
    public deleteRechtszitat(name: string, options?: RawAxiosRequestConfig) {
        return RechtszitatResourceApiFp(this.configuration).deleteRechtszitat(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechtszitatResourceApi
     */
    public findRechtszitat(name: string, options?: RawAxiosRequestConfig) {
        return RechtszitatResourceApiFp(this.configuration).findRechtszitat(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VORLAGE_READ or ADMIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechtszitatResourceApi
     */
    public getAllRechtszitat(options?: RawAxiosRequestConfig) {
        return RechtszitatResourceApiFp(this.configuration).getAllRechtszitat(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
     * @param {RechtszitatEditRest} rechtszitatEditRest 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechtszitatResourceApi
     */
    public updateRechtszitat(rechtszitatEditRest: RechtszitatEditRest, name: string, options?: RawAxiosRequestConfig) {
        return RechtszitatResourceApiFp(this.configuration).updateRechtszitat(rechtszitatEditRest, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VORLAGE_WRITE or ADMIN
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechtszitatResourceApi
     */
    public uploadRechtszitat(file: File, options?: RawAxiosRequestConfig) {
        return RechtszitatResourceApiFp(this.configuration).uploadRechtszitat(file, options).then((request) => request(this.axios, this.basePath));
    }
}

